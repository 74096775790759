import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Zephyr.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import ZephyrPng2 from "../../../../res/Photos site/Zephyr/_Graffiti_, Museum Boijmans van Beuningen, 1983..png"
import ZephyrPng3 from "../../../../res/Photos site/Zephyr/BLACK BOOKS zephyr.jpg"
import ZephyrPng4 from "../../../../res/Photos site/Zephyr/DONDI, FUTURA AND ZEPHYR PAINT A BUS IN CALIFORNIA, 1982.jpg"
import ZephyrPng5 from "../../../../res/Photos site/Zephyr/Moto Zephyr 2.png"
import ZephyrPng6 from "../../../../res/Photos site/Zephyr/Photo _ Zephyr.jpg"
import ZephyrPng7 from "../../../../res/Photos site/Zephyr/zephyr - by art of the title.jpg"
import ZephyrVid from "../../../../res/Photos site/Zephyr/zephyr.mp4"
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/zephyr/past-present-1.jpg"
import PastPresent2 from "../../../../res/zephyr/past-present-2.jpg"
import PastPresent3 from "../../../../res/zephyr/past-present-3.jpg"
// import Pdp from "../../../../res/zephyr/portrait.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "zephyr",
  name: "Zephyr",
  description: "Né Andrew Witten, Zephyr a commencé sa carrière artistique en 1977, en peignant son surnom Zephyr sur les wagons de métro. Il fait partie de la première vague d'artistes de graffiti à faire la transition vers les galeries. En 1982, il fait partie du premier groupe d'artistes à fonder la FUN Gallery à Greenwich Village, qui comptait à l'époque des icônes de l'art comme Futura 2000, Kenny Scharf, Keith Haring et Jean-Michel Basquiat. En 1983, son art fait partie d'une exposition qui fait le tour du Japon et à laquelle participent également Fab Five Freddy, Dominique Philbert, Futura 2000 et Dondi White. La même année, Zephyr a été engagé par le réalisateur Charlie Ahearn pour concevoir et diriger artistiquement la séquence titre du premier film hip-hop, Wild Style, dans lequel il figurait également.En 2001, il est co-auteur de la biographie Dondi White Style Master General : The Life of Graffiti Artist Dondi White et, en 2005, il a participé à l'exposition East Village USA organisée au New Museum. On a pu le voir dans les films Style Wars, The Art of Storytelling et Bomb It.",
  pdp: ZephyrPng3,
  alt_pdp: "Photo de profil de Zephyr.",
  photos: [
    { src: PastPresent1, name: "Zephyr" },
    { src: PastPresent2, name: "Zephyr" },
    { src: PastPresent3, name: "Zephyr" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};


// const Zephyr = () => {
//   return (
//     <div className={Wrapper}>
//       <div className={CardWrapper}>
//         <div className={ProfilWrapper} id="f">
//           <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
//         </div>
//         <div>
//           <h1 className={NameWrapper}>ZEPHYR</h1>
//           <h3 className={NameWrapper} style={{paddingTop: '0'}}>Né en 1961</h3>
//           <p className={DescriptionWrapper}>{infos.description}</p>
//         </div>
//       </div>
//       <div className={PhotosWrapper}>
//         <Title title={"Past or Present"} />
//         <div className={CarrouselWrapper2}>
//           <Carrousel images={infos.photos} />
//         </div>
//       </div>
    
//     </div>
//   );
// };

// export default Zephyr;
const Zephyr = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
     <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>ZEPHYR <h2 style={{paddingLeft: "16px"}}>(Andrew Witten - Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1961</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
          <ul className={ArtistInfos}>
            <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
            <br />
            <p style={{fontWeight: "bold"}}>2022</p>
            <p>- New York Street Style : création des années 80 - Ghost Galerie, Paris, juillet - septembre 2022.</p>
            <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022</p>
            <br />
            <p style={{fontWeight: "bold"}}>2021</p>
            <p>- Ghost DNA - exposition d’ouverture - opening exhibition, Ghost galerie, Paris, France, July 7th - September 4th.</p>
            <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021.</p>
            <br />
            <div className={More} id="more">
            <p style={{fontWeight: "bold"}}>2005</p>
            <p>- "East Village USA," The New Museum, New York, NY</p>
            <br />
            <p style={{fontWeight: "bold"}}>1999</p>
            <p>- Charas/El Bohio, New York, NY</p>
            <br />
            <p style={{fontWeight: "bold"}}>1997</p>
            <p>- Livestock Gallery, New York, NY</p>
            <br />
            <p style={{fontWeight: "bold"}}>1997</p>
            <p>- Livestock Gallery, New York, NY</p>
            <br />
            <p style={{fontWeight: "bold"}}>1992</p>
            <p>- Industry Detroit, Michigan</p>
            <br />
            <p style={{fontWeight: "bold"}}>1986</p>
            <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands</p>
            <br />
            <p style={{fontWeight: "bold"}}>1985</p>
            <p>- Center for Contemporary Art, Seattle, Washington</p>
            <br />
            <p style={{fontWeight: "bold"}}>1984</p>
            <p>- Taghinia Gallery, New York, NY</p>
            <p>- The Fun Gallery, West San Francisco, CA</p>
            <p>- The Fun Gallery, New York, NY</p>
            <br />
            <p style={{fontWeight: "bold"}}>1983</p>
            <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands</p>
            <br />
            <p style={{fontWeight: "bold"}}>1983</p>
            <p>- Art in Progress, Dusseldorf, Germany</p>
            <br />
            <p style={{fontWeight: "bold"}}>1982</p>
            <p>- 51X Gallery, New York, NY</p>
            <br />
            <p style={{fontWeight: "bold"}}>1982</p>
            <p>- Samson Gallery, Santa Cruz, CA</p>
            <br />
            <p style={{fontWeight: "bold"}}>1981</p>
            <p>- "Beyond Words," The Mudd Club, New York, NY</p>
            <br />
            <p style={{fontWeight: "bold"}}>1981</p>
            <p>- "New York/New Wave," P.S.1 New York, NY</p>
            <br />
            <p style={{fontWeight: "bold"}}>1980</p>
            <p id="end">- Fashion Moda, New York, NY</p>
            </div>
          </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Zephyr;