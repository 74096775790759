// extracted by mini-css-extract-plugin
export var ArtistDescription = "Zephyr-module--ArtistDescription--P2V-0";
export var ArtistInfos = "Zephyr-module--ArtistInfos--yF+HY";
export var ButtonWrapper = "Zephyr-module--ButtonWrapper --QUz+d";
export var CardWrapper = "Zephyr-module--CardWrapper--ILflL";
export var CarrouselWrapper2 = "Zephyr-module--CarrouselWrapper2--rYOGH";
export var Citations = "Zephyr-module--Citations--uLXij";
export var DescriptionWrapper = "Zephyr-module--DescriptionWrapper--oOgcS";
export var ImageWrapper = "Zephyr-module--ImageWrapper--aIF70";
export var LinkWrapper = "Zephyr-module--LinkWrapper--GTV-a";
export var MobileProtrait = "Zephyr-module--MobileProtrait--C9CAJ";
export var More = "Zephyr-module--More--7yVop";
export var MoreButton = "Zephyr-module--MoreButton--ivkpP";
export var NameWrapper = "Zephyr-module--NameWrapper--J1e-Y";
export var PdpWrapper = "Zephyr-module--PdpWrapper--SKeXQ";
export var PhotosWrapper = "Zephyr-module--PhotosWrapper--fi1gD";
export var ProfilWrapper = "Zephyr-module--ProfilWrapper--5lt42";
export var TitleWrapper = "Zephyr-module--TitleWrapper--MOoLa";
export var Wrapper = "Zephyr-module--Wrapper--K9Q0M";